  
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
  
  .listgroupscroll {
    max-height    : 140px!important;
    overflow      : scroll!important;
    margin-bottom : 5px!important;
  }
  
  .react-autosuggest__container {
     flex     : 1 1 auto;
  /*    width    : 150px !important; */
  /*    6-28-2021 v2 full width wanted */ 
 
    }
  
  .react-autosuggest__input {
    width  : 100%;
    height : 30px;
    padding: 10px 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius:  5;
    border-bottom-right-radius: 5;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top:   60px;
    left:  20px;
    width: 220px;
    height: 60vh;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    background-image: linear-gradient(-4deg, #b9812c 0%, #e3c74d 100%);
    background-color : lightsteelblue;
    color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;

    overflow: auto;
    scroll-behavior: auto; 
   
    
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .unselected_listItem {
    display         : flex;
    flex-direction  : row;
  }

  .selected_listitem {
    display           : flex;
    flex-direction    : row;
    background-color  :rgba(207, 220, 221, 0.6);
  }

.title_listItem{
    display: flex;
    justify-content: space-between;
}

.email-footer{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

/* button color scheme*/

.MuiFab-primary{
  background-image: -webkit-linear-gradient(94deg, #b9812c 0%, #e3c74d 100%);
  background-image: -o-linear-gradient(94deg, #b9812c 0%, #e3c74d 100%);
  background-image: linear-gradient(94deg, #b9812c 0%, #e3c74d 100%);
  color: #fff
}

.MuiFab-secondary{
  background-color: #e3c74d !important;
  color: #fff
}

.Toastify__toast-body{
  white-space: pre-line;
}


.details_description_text {
  white-space: pre-wrap;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
}

.dl_option_filters {
   width: -webkit-fill-available;
   height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
